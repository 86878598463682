const determineHost = () => {
	// 👇 because: https://stackoverflow.com/a/70504910/3633109
	// ie, staging-and-preview-web
	if (process.env.NEXT_PUBLIC_VERCEL_URL && !process.env.NEXT_PUBLIC_VERCEL_URL.startsWith("production")) {
		if (process.env.NEXT_PUBLIC_VERCEL_URL === "staging-and-preview-web.vercel.app") {
			return "https://staging.metmuseum.org"
		} else {
			return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		}
	}

	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3000"
	}

	// production
	return "https://www.metmuseum.org"
}

export const HOST = determineHost();

export const COLLECTION_API_OBJECT_ENDPOINT = `https://collectionapi.metmuseum.org/api/collection/v1/object`; // "Ron's API" good but not comprehensive
export const COLLECTION_API_OBJECT_PAGE_ENDPOINT = `https://collectionapi.metmuseum.org/private/collection/v2/objectPage`; // API for object pages, has complete object data
export const COLLECTION_GRAPHQL_URL = `https://collectionapi.metmuseum.org/graphql`;
export const GALLERY_API_URL = 'https://www.metmuseum.org/rodan/location-info/gallery'
export const STUDYCENTER_API_URL= 'https://www.metmuseum.org/rodan/study-center/object'
export const COLLECTION_API_SEARCH = 'https://collectionapi.metmuseum.org/public/collection/v1/search';
export const SOLR_SEARCH_ENDPOINT = 'https://www.metmuseum.org/rodan/api/search'
export const LAUDER_COLLECTION_ID = 268
export const SANITY_API_TOKEN = process.env.SANITY_API_TOKEN || "testing token"
export const SANITY_API_VERSION = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2024-12-20'
export const SANITY_DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET
export const SANITY_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || "cctd4ker"
export const OVERLY_SIMPLE_API_AUTH_TOKEN = process.env.OVERLY_SIMPLE_API_AUTH_TOKEN || "testing token"
export const CURRENT_YEAR = new Date().getFullYear()
export const IMPERVA_SAFELIST_SECRET = process.env.COLLECTION_API_SAFELIST_SECRET
export const NYC_IANA_TIMEZONE = "America/New_York" // 🇺🇸 🗽
export const SUPPORTED_LANGUAGES = [
	{ id: 'en', name: 'English', localName: 'English'},
	{ id: 'es', name: 'Spanish', localName: 'Español' },
	{ id: 'pt', name: 'Portugese', localName: 'Português' },
	{ id: 'fr', name: 'French', localName: 'Français' },
	{ id: 'it', name: 'Italian', localName: 'Italiano' },
	{ id: 'de', name: 'German', localName: 'Deutsch' },
	{ id: 'ja', name: 'Japanese', localName: '日本語' },
	{ id: 'ko', name: 'Korean', localName: '한국어' },
	{ id: 'zh', name: 'Chinese', localName: '中文 (简体)' },
	{ id: 'ru', name: 'Russian', localName: 'Pусский' },
]
export const SUPPORTED_LOCALES = SUPPORTED_LANGUAGES.map(language => language.id)
export const MOTHRA_ENDPOINT = "https://www.metmuseum.org/mothra/collectionlisting/search"
export const SITE_SEARCH_PER_PAGE = 10
export const CONSERVATION_OUR_WORK = "/about-the-met/conservation-and-scientific-research/projects"
export const CONSERVATION_OUR_HISTORY = "/about-the-met/conservation-and-scientific-research/conservation-stories/history-of-conservation"
export const ABOUT_THE_MET = "/about-the-met"
export const DEPARTMENTS_ALL = "/about-the-met/departments/all"
export const NO_IMAGE_AVAILABLE = "/content/img/placeholders/NoImageAvailableIcon.png"
export const NO_IMAGE_AVAILABLE_534 = "/content/img/placeholders/noimage534x534.png"
export const ART_COLLECTION_SEARCH = "/art/collection/search"
export const MET_FIFTH_BUILDING_ID = "4f2f91fd-1bbe-46b2-a392-addb41d5a27f"
export const CLOISTERS_BUILDING_ID = "3e6a0afc-79ec-4686-81f0-ad920202ba4f"
